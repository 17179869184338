<template>
  <div>
    <b-row>
      <b-col cols="12">
        <b-card no-body class="mb-1">
          <div
            class="d-flex justify-content-between align-items-center px-1 py-1"
          >
            <div>
              <h3 class="m-0 py-1">
                {{ $t("Usuarios") }}
              </h3>
            </div>

            <div class="d-flex align-items-center">
              <div class="d-flex align-items-right">
                <b-link :to="{ name: 'createUser' }">
                  <b-button variant="primary" class="mr-1 text-nowrap">
                    {{ $t("AnyadirNuevoUsuario") }}
                  </b-button>
                </b-link>
              </div>
              <span
                class="ml-1 cursor-pointer"
                @click="visibleFilter = !visibleFilter"
              >
                <feather-icon icon="FilterIcon" size="20" />
              </span>
            </div>
          </div>

          <div>
            <b-collapse id="collapse" v-model="visibleFilter" class="mt-2">
              <div class="px-2">
                <b-row>
                  <b-col
                    v-if="role === 'admin_empresa' && !currentClient"
                    sm="4"
                  >
                    <b-form-group
                      :label="$t('Cliente')"
                      label-for="filter-client"
                    >
                      <v-select
                        v-model="client"
                        label="name"
                        :filterable="true"
                        :searchable="true"
                        :options="listClients"
                        :placeholder="$t('Cliente')"
                      >
                        <template slot="option" slot-scope="option">
                          {{ option.name }}
                        </template>
                        <template slot="selected-option" slot-scope="option">
                          {{ option.name }}
                        </template>
                      </v-select>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group
                      :label="$t('Busqueda')"
                      label-for="filter-search"
                    >
                      <b-row>
                        <b-col sm="8">
                          <b-form-input
                            v-model="searchTerm"
                            :placeholder="$t('Busqueda')"
                            type="text"
                            class="d-inline-block"
                          />
                        </b-col>
                        <b-col sm="4" style="padding-left: 0px">
                          <b-button variant="primary" @click="handleSearch">
                            {{ $t("Buscar") }}
                          </b-button>
                        </b-col>
                      </b-row>
                    </b-form-group>
                  </b-col>
                  <b-col sm="4">
                    <b-form-group :label="$t('Rol')" label-for="account-rols">
                      <v-select
                        v-model="roles"
                        label="display_name"
                        :filterable="false"
                        :searchable="false"
                        :options="searchRoles"
                        multiple
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>
            </b-collapse>
          </div>
          <!--  <div
            class="d-flex justify-content-between align-items-center px-1 py-50"
          >
            <div class="col-3">
              <h3 class="m-0">{{ $t("Usuarios") }}</h3>
            </div>
            <div class="d-flex align-items-center">

            </div>
          </div> -->
          <b-table
            v-if="items"
            hover
            responsive
            :items="items"
            :fields="seleccionarcolumnas"
          >
            <template #cell(fullName)="data">
              <div class="d-flex align-items-center">
                <b-avatar size="md" :src="data.item.avatar" />
                <span class="text-nowrap ml-1">{{ data.item.name }}</span>
              </div>
            </template>
            <template #cell(status)="data">
              <b-badge :variant="statusVariant(data.item.active)">
                {{ $t(statusVariantText(data.item.active)) }}
              </b-badge>
            </template>
            <template #cell(clients)="data">
              <span
                v-for="(c, index) in data.item.clients"
                :key="`${c.id}-${data.item.id}`"
              >
                <span v-if="index !== 0">, </span>{{ c.name }}
              </span>
            </template>
            <template #cell(roles)="data">
              <span
                v-for="(c, index) in data.item.roles"
                :key="`${c.id}-${data.item.id}`"
              >
                <span v-if="index !== 0">, </span>{{ c.display_name }}
              </span>
            </template>
            <template #cell(actions)="data">
              <span>
                <b-link
                  :to="{ name: 'viewUser', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon icon="EyeIcon" size="16" />
                </b-link>
                <b-link
                  :to="{ name: 'editUser', params: { id: data.item.id } }"
                  class="mr-1"
                >
                  <feather-icon icon="Edit2Icon" size="16" />
                </b-link>
                <span
                  class="text-danger cursor-pointer"
                  @click="deleteUser(data.item.id, data.item.name)"
                >
                  <feather-icon icon="TrashIcon" />
                </span>
              </span>
            </template>
          </b-table>
        </b-card>
        <div class="d-flex align-items-center justify-content-between mb-2">
          <div class="d-flex align-items-center mb-0">
            <span class="text-nowrap"> {{ $t("PorPagina") }}: </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="ml-50 mr-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap">
              {{ $t("Total") }}: {{ totalItems }}</span
            >
          </div>
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItems"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  BBadge,
  BPagination,
  BFormInput,
  BFormSelect,
  BCard,
  BTable,
  BButton,
  BAvatar,
  BCol,
  BRow,
  BLink,
  BInputGroup,
  BInputGroupPrepend,
  BFormGroup,
  BCollapse,
} from "bootstrap-vue";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import store from "@/store/index";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import {
  FlatPickrToTimestamp,
  TimestampToFlatPickr,
  TimestampToFlatPickrWithTime,
} from "@/libs/helpers";
import { config } from "@/shared/app.config";

export default {
  components: {
    BTable,
    BBadge,
    BLink,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    flatPickr,
    BRow,
    vSelect,
    BCol,
    BCollapse,
    BButton,
    BAvatar,
  },
  data() {
    return {
      searchRoles: [],
      roles: "",
      log: [],
      type: "",
      visibleFilter: false,
      currentPage: 1,
      pageLength: 10,
      dir: false,
      pages: ["10", "25", "50"],
      client: "",
      columns: [
        {
          label: this.$t("Nombre"),
          key: "fullName",
        },
        {
          label: this.$t("Email"),
          key: "email",
        },
        {
          label: this.$t("TareasPendientes"),
          key: "total_todo_tasks_responsible",
        },
        {
          label: this.$t("Cliente"),
          key: "clients",
        },
        {
          label: this.$t("Rol"),
          key: "roles",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-left",
          thStyle: { minWidth: "15rem" },
        },
      ],
      columnsClient: [
        {
          label: this.$t("Nombre"),
          key: "fullName",
        },
        {
          label: this.$t("Email"),
          key: "email",
        },
        {
          label: this.$t("Username"),
          key: "username",
        },
        {
          label: this.$t("TareasPendientes"),
          key: "total_todo_tasks_responsible",
        },
        {
          label: this.$t("Rol"),
          key: "roles",
        },
        {
          label: this.$t("Estado"),
          key: "status",
        },
        {
          label: this.$t("Acciones"),
          key: "actions",
          class: "text-left",
          thStyle: { minWidth: "15rem" },
        },
      ],
      searchTerm: "",
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      currentCompany: "companies/getCurrentCompany",
      currentClient: "clients/getCurrentClient",
      items: "users/getItems",
      totalItems: "users/getTotalItems",
      listClients: "clients/getItems",
      currentUser: "auth/getUser",
      role: "auth/getRole",
      selectRoles: "users/getRoles",
    }),
    seleccionarcolumnas() {
      if (this.currentClient) {
        return this.columnsClient;
      }
      return this.columns;
    },
    statusVariant() {
      const statusColor = {
        1: "light-success",
        0: "light-danger",
        pending: "light-warning",
      };

      return (status) => statusColor[status];
    },
    statusVariantText() {
      const statusText = {
        1: "ActivoUser",
        0: "Desactivado",
        pending: "Pendiente",
      };

      return (status) => statusText[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  watch: {
    client() {
      this.chargeData();
    },
    roles() {
      this.chargeData();
    },
  },
  beforeCreate() {},
  created() {
    this.getRoles();

    this.searchRoles = this.selectRoles.filter(
      (item) => item.name !== "client"
    );

    this.getListClients({
      company: this.currentCompany.id,
      page: 1,
      per_page: 999999,
      search: "",
    });
    this.chargeData();
  },
  methods: {
    ...mapActions({
      list: "users/getListUsers",
      delete: "users/delete",
      getListClients: "clients/getListClients",
      getRoles: "users/getRoles",
    }),
    chargeData() {
      let cliente = this.currentClient ? this.currentClient.id : "";
      if (this.role !== "admin_cliente" && cliente === "") {
        cliente = this.client ? this.client.id : "";
      }
      const rolesSelect = [];
      let mapRoles = [];

      if (this.roles.length > 0) {
        this.roles.forEach((element) => {
          rolesSelect.push(element.name);
        });
      }

      //role control
      if (rolesSelect.length === 0) {
        mapRoles = [];
        this.searchRoles.forEach((element) => {
          mapRoles.push(element.name);
        });
      } else {
        mapRoles = rolesSelect;
      }

      this.list({
        company: this.currentCompany.id,
        client: cliente,
        page: this.currentPage,
        per_page: this.pageLength,
        search: this.searchTerm,
        roles: mapRoles,
      });
    },
    handleSearch() {
      this.currentPage = 1;
      this.chargeData();
    },
    handleChangePage(page) {
      this.currentPage = page;
      this.chargeData();
    },
    handlePageChange() {
      this.currentPage = 1;
      this.chargeData();
    },
    onSortChange(params) {
      this.currentPage = 1;
      this.chargeData();
    },
    deleteUser(id, name) {
      this.$bvModal
        .msgBoxConfirm(this.$t("EliminarConfirm", { nombre: name }), {
          bodyClass: "bodyDelete",
          footerClass: "footerDelete",
          okVariant: "danger",
          okTitle: this.$t("Eliminar"),
          cancelTitle: this.$t("Cancelar"),
          cancelVariant: "outline-danger",
          centered: true,
        })
        .then((value) => {
          if (value === true) {
            this.delete(id);
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
